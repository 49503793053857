import { useStaticQuery, graphql } from "gatsby";

export const useAllContentfulContentHubPageTypes = () => {
  const { allContentfulContentHubContentTypePage } = useStaticQuery(
    graphql`
      query ContentfulContentContentTypeQuery {
        allContentfulContentHubContentTypePage(
          sort: { fields: ordinal, order: ASC }
        ) {
          group(field: node_locale) {
            fieldValue
            edges {
              node {
                id
                contentful_id
                __typename
                name
              }
            }
          }
        }
      }
    `
  );

  return allContentfulContentHubContentTypePage.group.reduce(
    (contentTypes, group) => {
      const { fieldValue, edges } = group;
      contentTypes[fieldValue.slice(0, 2)] = edges.map(
        (edge) => edge.node.name
      );
      return contentTypes;
    },
    {}
  );
};
