/** @jsx jsx */
import { jsx, Box, Button, Flex, Themed, useThemeUI } from "theme-ui";
import { useContext, useEffect, useState } from "react";
import ContentHubPager from "./ContentHubPager";
import { LocalizationContext } from "../context/LocalizationContext";

const ThemeAndContentTypeFilters = ({
  themes,
  contentTypes,
  allContentHubPages,
  language,
}) => {
  const { translate } = useContext(LocalizationContext);
  const { theme } = useThemeUI();

  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedContentTypes, setSelectedContentTypes] = useState([]);
  const [filteredPages, setFilteredPages] = useState([]);

  useEffect(() => {
    let filteredContent =
      selectedThemes.length > 0 || selectedContentTypes.length > 0
        ? allContentHubPages
        : [];

    if (selectedThemes.length > 0) {
      filteredContent = filteredContent.filter(
        page =>
          page.node.theme && selectedThemes.includes(page.node.theme.theme)
      );
    }

    if (selectedContentTypes.length > 0) {
      filteredContent = filteredContent.filter(page =>
        selectedContentTypes.includes(page.node.contentType.name)
      );
    }
    filteredContent.sort((a, b) => a.node.publishDate > b.node.publishDate);
    setFilteredPages(filteredContent);
  }, [allContentHubPages, selectedThemes, selectedContentTypes]);

  const handleClick = (filter, stateCallback) => {
    stateCallback(prevState => {
      const newState = [...prevState];
      if (newState.includes(filter)) {
        return newState.filter(item => item !== filter);
      }
      newState.push(filter);
      return newState;
    });
  };

  const renderLink = (arr, handleClick, state, setStateFunction) => {
    return arr.map((item, index) => {
      const isSelected = state.includes(item);
      return (
        <Button
          key={`taglinks-${index}`}
          sx={{
            variant: isSelected ? "buttons.primary" : "buttons.secondary",
            whiteSpace: "normal",
            fontWeight: "normal",
            px: "1em",
            border: "none",
            ":hover": {
              border: theme.colors.hover,
              borderWidth: "1px",
              borderStyle: "solid",
              color: "white",
              bg: "hover",
            },
          }}
          onClick={() => handleClick(item, setStateFunction)}
        >
          {item}
        </Button>
      );
    });
  };

  const isInInitialState =
    [...selectedThemes, ...selectedContentTypes].length === 0;
  return (
    <Box>
      <Themed.h2>
        {translate("contentHub.filterTitle", null, language)}
      </Themed.h2>
      <Flex
        sx={{
          justifyContent: "start",
          alignItems: "center",
          flexWrap: ["wrap"],
        }}
      >
        <Themed.h3
          sx={{ display: "inline-block", width: "15%", marginRight: "1em" }}
        >
          {translate("contentHub.topic", null, language)}
        </Themed.h3>
        <Flex sx={{ display: "inline-block", border: "1px solid #008A28" }}>
          {renderLink(themes, handleClick, selectedThemes, setSelectedThemes)}
        </Flex>
      </Flex>
      <Flex
        sx={{
          justifyContent: "start",
          alignItems: "center",
          flexWrap: ["wrap"],
        }}
      >
        <Themed.h3
          sx={{ display: "inline-block", width: "15%", marginRight: "1em" }}
        >
          {translate("contentHub.contentType", null, language)}
        </Themed.h3>
        <Flex sx={{ display: "inline-block", border: "1px solid #008A28" }}>
          {renderLink(
            contentTypes,
            handleClick,
            selectedContentTypes,
            setSelectedContentTypes
          )}
        </Flex>
      </Flex>
      <ContentHubPager
        contentHubPages={filteredPages}
        sx={{ my: "2em" }}
        language={language}
        isInInitialState={isInInitialState}
      />
    </Box>
  );
};

export default ThemeAndContentTypeFilters;
