import { useStaticQuery, graphql } from "gatsby";

export const useAllContentfulContentHubThemes = () => {
  const { allContentfulContentHubThemePage } = useStaticQuery(
    graphql`
      query ContentfulContentHubThemeQuery {
        allContentfulContentHubThemePage(
          sort: { fields: ordinal, order: ASC }
        ) {
          group(field: node_locale) {
            fieldValue
            edges {
              node {
                id
                contentful_id
                __typename
                theme
              }
            }
          }
        }
      }
    `
  );

  return allContentfulContentHubThemePage.group.reduce((themes, group) => {
    const { fieldValue, edges } = group;
    themes[fieldValue.slice(0, 2)] = edges.map((edge) => edge.node.theme);
    return themes;
  }, {});
};
