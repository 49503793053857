/** @jsx jsx */
import { jsx, Box, Button, Flex, Themed, useThemeUI } from "theme-ui";

const ContentThemeSelector = ({ title, themes, selectedThemes, callback, ...props }) => {
  const { theme } = useThemeUI();
  return (
    <Flex sx={{ mx: "-0.2em", justifyContent: "start", alignItems: "center", flexWrap: "wrap" }} {...props}>
      {title && <Themed.h2 sx={{ marginRight: "1em" }}>{title}</Themed.h2>}
      <Box>
        { themes.map((item, index) => {
              return (
                <Button
                  key={`taglinks-${index}`}
                  aria-controls={`content-card-theme-${item}`}
                  sx={{
                    variant: selectedThemes.includes(item) ? "buttons.primary" : "buttons.secondary",
                    whiteSpace: "normal",
                    mx: "0.2em",
                    my: "1em",
                    fontWeight: "bold",
                    textAlign: "center",
                    ":hover": {
                      border: theme.colors.hover,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      color: "white",
                      bg: "hover",
                    },
                  }}
                  onClick={() => callback(item)}
                >
                  {item}
                </Button>
              );
          }) }
      </Box>
    </Flex>
  );
};

export default ContentThemeSelector;
