/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import Link from "./Link";
import Helmet from "react-helmet";
import { getUrlFromSlug } from "../utils/utils";
import { useSitePages } from "../hooks/useSitePages";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { getLocalizedUrlAndTitleFromSlug } from "../utils/utils";

const BreadCrumbs = ({ slug, navigationTitle, parentPage, language }) => {
  const nodes = useSitePages();
  const { defaultTitle, siteUrl } = useSiteMetadata();

  const {
    title: localizedFrontPageTitle,
    slug: localizedFrontPageSlug,
  } = getLocalizedUrlAndTitleFromSlug("/", language, nodes);

  const crumbs = [
    {
      slug: localizedFrontPageSlug,
      navigationTitle: localizedFrontPageTitle,
    },
    parentPage
      ? {
          slug: parentPage.slug,
          navigationTitle: parentPage.navigationTitle,
        }
      : null,
    {
      slug,
      navigationTitle,
    },
  ].filter(Boolean);

  let pos = 1;
  let itemitem;
  let itemname;
  let breadcrumbList = [];
  let target;

  for (let link of crumbs) {
    if (pos == 1) {
      itemname = defaultTitle;
      itemitem = siteUrl;
    } else {
      itemname = link.navigationTitle;
      target = getUrlFromSlug(link.slug, nodes);
      itemitem = siteUrl + target;
    }
    breadcrumbList.push({
      "@type": "ListItem",
      position: (pos += 1),
      name: itemname,
      item: itemitem,
    });
  }
  return (
    <div sx={{ "@media print": { display: "none" } }}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: breadcrumbList,
          })}
        </script>
      </Helmet>
      <Themed.ul
        sx={{
          display: "flex",
          flexWrap: "wrap",
          pl: 0,
          listStyle: "none",
          fontSize: 0,
        }}
      >
        {crumbs.map((link, index) => (
          <li
            key={link.navigationTitle}
            sx={{
              "::after": {
                display: "inline-block",
                px: 1,
                content: '"/"',
              },
              ":last-of-type::after": {
                display: "none",
              },
            }}
          >
            <Link
              target={link.slug}
              sx={{
                fontFamily: index === crumbs.length - 1 ? "heading" : "body",
                color: "text",
              }}
            >
              {link.navigationTitle}
            </Link>
          </li>
        ))}
      </Themed.ul>
    </div>
  );
};
export default BreadCrumbs;
