/** @jsx jsx */
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useContext, useState } from "react";
import Helmet from "react-helmet";
import { Container, jsx } from "theme-ui";
import BreadCrumbs from "../components/BreadCrumbs";
import ContentCardList, {
  ContentCardTypes,
} from "../components/ContentCardList";
import ContentThemeSelector from "../components/ContentThemeSelector";
import Layout from "../components/Layout";
import RichText2 from "../components/RichText2";
import SEO from "../components/SEO";
import ThemeAndContentTypeFilters from "../components/ThemeAndContentTypeFilters";
import { LocalizationContext } from "../context/LocalizationContext";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { useAllContentfulContentHubPages } from "../hooks/useAllContentfulContentHubPages";
import { useAllContentfulContentHubPageTypes } from "../hooks/useAllContentfulContentHubPageTypes";
import { useAllContentfulContentHubThemes } from "../hooks/useAllContentfulContentHubThemes";
import { useSitePages } from "../hooks/useSitePages";
import { createImageUrl, getUrlFromSlug } from "../utils/utils";

const ContentHubFrontPageTemplate = ({ data, pageContext }) => {
  const { language, locale, paths } = pageContext;
  const { translate } = useContext(LocalizationContext);
  const allThemes = useAllContentfulContentHubThemes();
  const [latestContentFilters, setLatestContentFilters] = useState([]);
  const allContentHubPages = useAllContentfulContentHubPages();

  const contentTypes = useAllContentfulContentHubPageTypes();

  const nodes = useSitePages();
  const {
    pageType,
    title,
    navigationTitle,
    metaKeywords,
    metaDescription,
    headerImage,
    slug,
    hidden,
    hideBreadcrumb,
    contentArea,
  } = data.contentfulPage;

  const { defaultDescription, siteUrl, defaultImage, author } =
    useSiteMetadata();

  const metadataTitle = title;

  const metadataDescription =
    metaDescription &&
    metaDescription.internal &&
    metaDescription.internal.content
      ? metaDescription.internal.content
      : defaultDescription;

  const metadataKeywords = metaKeywords ? metaKeywords : "";

  let metaImage =
    headerImage && headerImage.gatsbyImageData
      ? createImageUrl(headerImage.gatsbyImageData)
      : siteUrl + defaultImage;

  const setLatestFilters = (filter) => {
    setLatestContentFilters((prevState) => {
      const newState = [...prevState];
      if (newState.includes(filter)) {
        return newState.filter((item) => item !== filter);
      }
      newState.push(filter);
      return newState;
    });
  };

  const contentPagesByTheme = allThemes[language].map((theme) => {
    return {
      theme,
      items: allContentHubPages[language]
        .filter((edge) => edge.node.theme && edge.node.theme.theme === theme)
        .slice(0, 3),
    };
  });

  return (
    <Layout pageContext={pageContext} pageType={pageType}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Article",
            headline: metadataTitle,
            image: {
              "@type": "ImageObject",
              url: `${metaImage}`,
            },
            publisher: {
              "@type": "Organization",
              name: author,
              logo: {
                "@type": "ImageObject",
                url: `${defaultImage}`,
              },
            },
            url: siteUrl + getUrlFromSlug(slug, nodes),
            name: title,
            description: metadataDescription,
            mainEntityOfPage: siteUrl + getUrlFromSlug(slug, nodes),
          })}
        </script>
      </Helmet>
      <SEO
        lang={language}
        title={metadataTitle}
        description={metadataDescription}
        keywords={metadataKeywords}
        image={metaImage}
        paths={paths}
        hidden={hidden}
      />
      {!hideBreadcrumb && (
        <Container>
          <BreadCrumbs
            slug={slug}
            navigationTitle={navigationTitle}
            language={language}
          />
        </Container>
      )}
      <Container id="content">
        {headerImage && <GatsbyImage image={headerImage.gatsbyImageData} />}
        <RichText2 content={contentArea} locale={locale} language={language} />
        <ContentThemeSelector
          title={translate("contentHub.topicOfInterest", null, language)}
          themes={allThemes[language]}
          selectedThemes={latestContentFilters}
          callback={setLatestFilters}
        />
        {Array.isArray(contentPagesByTheme) &&
          contentPagesByTheme.map((item, index) => {
            const { theme, items } = item;
            if (
              latestContentFilters.length === 0 ||
              latestContentFilters.includes(theme)
            ) {
              return (
                <ContentCardList
                  key={`content-card-theme-${theme}`}
                  title={theme}
                  contentHubPages={items}
                  language={language}
                  type={ContentCardTypes.FRONTPAGE}
                  index={index}
                  id={`content-card-theme-${theme}`}
                />
              );
            }
          })}
        <ThemeAndContentTypeFilters
          themes={allThemes[language]}
          contentTypes={contentTypes[language]}
          allContentHubPages={allContentHubPages[language]}
          language={language}
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      contentful_id
      __typename
      pageType
      title
      navigationTitle
      slug
      parentPage {
        ... on ContentfulProductPage {
          slug
          navigationTitle
        }
        ... on ContentfulPage {
          slug
          navigationTitle
        }
        ... on ContentfulThemePage {
          slug
          navigationTitle
        }
      }
      metaKeywords
      metaDescription {
        internal {
          content
        }
      }
      hideTitle
      headerImage {
        gatsbyImageData(quality: 65, width: 1600, aspectRatio: 3.2)
      }
      contentArea {
        raw
        references {
          ... on Node {
            ...fragmentContentfulFrontpage
          }
          ... on Node {
            ...fragmentContentfulThemePage
          }
          ... on Node {
            ...fragmentContentfulProductPage
          }
          ... on Node {
            ...fragmentContentfulPage
          }
          ... on Node {
            ...fragmentContentfulContentHubThemePage
          }
          ... on Node {
            ...fragmentContentfulContentHubPage
          }
          ... on Node {
            ...fragmentContentfulContentHubContentTypePage
          }
          ... on Node {
            ...fragmentContentfulContentHubTopicPage
          }
          ... on Node {
            ...fragmentContentfulContentfulInsuranceTermsPage
          }
          ...fragmentContentfulGrid
          ...fragmentContentfulAccordion
          ...fragmentContentfulAsset
          ...fragmentContentfulCallToAction
          ...fragmentContentfulComponent
          ...fragmentContentfulCallToAction
          ...fragmentContentfulGrid
          ... on Node {
            ...fragmentContentfulDynamicSection
          }
        }
      }
      hideBreadcrumb
      hidden
    }
  }
`;
export default ContentHubFrontPageTemplate;
